<template>
    <div>
        <!-- <Header></Header> -->
      <div class="banner" style="width:100%; height:auto; text-align: center;">
        <img :src="Src_" alt="" style="width:100%; height:100%;">
      </div>
      <div class="main">
        <div class="main_text_top">
          <!-- <span style="font-weight:bolder; font-size:26px; border-left:3px solid #15aec4;border-bottom:3px solid #15aec4">公司简介</span> -->
          <div class="introduce">
            德安健康 —— 致力于打造数智化综合健康管理综合服务平台
          </div>
          <div style="line-height:44px; font-size:20px;" class="int_text">
            北京德安健康管理有限公司（简称德安健康）成立于2016年，公司总部位于北京市朝阳区望京顺北大街诚盈中心，德安健康致力于打造数智化综合健康管理综合服务平台。<br />
  
            公司拥有深厚的医疗专家资源和健康机构服务资源，致力于打造包括高端健康管理、高端就医在内的全生命周期的健康管理服务平台，为企业、机构、家庭和个人提供数智化医疗和健康管理服务。<br />
  
            德安健康私人健康医疗服务，以北京为中心，在全国主要城市和海外建立起全面专业的医疗专家网络并积累20余年成功服务高端客户的经验，致力为客户提供全面的医疗保健服务和优越的顾问式医疗服务体验。<br />
  
            德安健康为金融机构提供贵宾客户增值服务，目前已为中国银行、中信银行、民生银行、兴业银行、渤海银行、人保健康、人保寿险、君康人寿、亚太财产等金融机构提供健康管理服务，得到广大金融机构客户的高度认可。
          </div>
          <div class="introduce">公司文化</div>
  
          <div style="line-height:44px; font-size:20px;" class="int_text">
            德行天下，安康于世。<br />
            德安使命：为中国人的健康保驾护航<br />
            德安愿景：致力于打造数智化综合健康管理综合服务平台<br />
            德安价值观：客户至上、诚信正直、追求卓越、开放进取、创造快乐、高效专业
          </div>
          <div class="introduce">服务网络</div>
          <div style="line-height:44px; font-size:20px;" class="int_text">
            300+ 城市<br />
            3400+公立医院：2800+西医院、620+中医院<br />
            3000+体检网点：2200+公立医院体检中心、800+品牌体检机构<br />
            500+品质口腔诊所、200+专业心理咨询机构<br />
            150000+医生、60000+护士、2000+心理咨询
          </div>
        </div>
      </div>
      <div style="width: 100%; height:100px;"></div>
      <Footer></Footer>
    </div>
  </template>
  <script>
  import Header from './Layout'
  import Footer from './footer'
import { computed } from 'vue'
  export default{
    components:{Header,Footer},
    data(){
        return{

        }
    },
    computed:{
      Src_() {
            let w = document.body.clientWidth;
            if (w > 600) {
                return require('../assets/service2.png')
            } else {
                return require('../assets/service2_2.png')
            }
        },
    }
  }
  </script>
  <style scoped>
  @media screen and (min-width: 800px) {
    .banner{
        height: auto !important;
    }
}
  @media screen and (max-device-width: 600px) {
    .banner {
      height: auto !important;
    }
    .introduce {
      font-size: 18px !important;
    }
    .int_text{
      font-size: 14px !important;
    }
  }
  .main {
    width: 100%;
    position: relative;
  }
  .main_text_top {
    width: 70%;
    margin: 0 auto;
  }
  .introduce {
    font-size: 30px;
    text-align: center;
    padding: 50px 0;
    margin-top: 30px;
    margin-bottom: 10px;
  }
  </style>